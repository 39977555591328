import { useStaticQuery, graphql } from 'gatsby';

export function useSiteMetadata() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            orgNumber
            email
            leiNumber
            apiHost
            country
          }
        }
      }
    `,
  );

  return { ...site.siteMetadata };
}
