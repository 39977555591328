import { useStaticQuery, graphql } from 'gatsby';

export function useProducts() {
  const { allProduct } = useStaticQuery(
    graphql`
      query {
        allProduct(sort: { fields: period, order: ASC }) {
          nodes {
            id
            period
            price
            currency
            description
          }
        }
      }
    `,
  );

  return allProduct.nodes;
}
