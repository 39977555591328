import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { injectIntl } from 'gatsby-plugin-intl';

import { useSiteMetadata } from '../../hooks';

const SEO = ({
  intl, title, description, meta,
}) => {
  const { title: metaTitle } = useSiteMetadata();
  const seoMetaTitle = title || (intl.formatMessage({ id: 'title' }) || metaTitle);
  const seoMetaDescription = description || intl.formatMessage({ id: 'description' });

  return (
    <Helmet
      htmlAttributes={{
        lang: intl.locale,
      }}
      title={seoMetaTitle}
      // titleTemplate={title && `%s | ${seoMetaTitle}`}
      titleTemplate={title && `%s | ${metaTitle}`}
      meta={[
        {
          name: 'description',
          content: seoMetaDescription,
        },
        {
          property: 'og:title',
          content: seoMetaTitle,
        },
        {
          property: 'og:description',
          content: seoMetaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
      ].concat(meta)}
    />
  );
};

SEO.defaultProps = {
  title: '',
  description: '',
  meta: [],
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

export default injectIntl(SEO);
